import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "menuLabel": "Tokens",
  "sortOrder": 5
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Tokens`}</h1>
    <p className="p-large">Design tokens are small visual design decisions like colors, fonts, animation, etc. that are a core part of the Caesars UI. We use these instead of hard-coded values so we can provide a consistent experience across platforms and scale seamlessly.</p>
    <h4>{`Released`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tokens/border-width"
        }}>{`Border Width`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tokens/border-radius"
        }}>{`Border Radius`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tokens/box-shadow"
        }}>{`Box Shadow`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tokens/colors"
        }}>{`Colors`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/tokens/spacing"
        }}>{`Spacing`}</a></li>
    </ul>
    <h4>{`Upcoming Tokens`}</h4>
    <ul>
      <li parentName="ul">{`Motion`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      